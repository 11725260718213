import React from "react"

import Layout from "../components/layout"
import dissertationDoc from "../documents/FCEnriqueMiguelFiguerolaGomez.pdf"

const DissertationPage = () => (
  <Layout>
    <section className="content__body--dissertation">
       <object data={ dissertationDoc } type='application/pdf'>
       </object>
    </section>
  </Layout>
)

export default DissertationPage


